import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAllSerialiParticolare(token, id_particolare) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/get_all_seriali_particolare?token="+token+"&id_particolare="+id_particolare).then(response => {
            return response.data;
        });
    },
    async updateOnePasso(token, id_pezzo, id_passo, tipo, id_utente) {
        let params = {
          id_pezzo, 
          id_passo, 
          tipo, 
          id_utente
        }
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/update_one_passo?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFile(token, formData, id_pezzo, id_passo, codice_commessa, part_number) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/upload_image_passo?token="+token+'&id_pezzo='+id_pezzo+'&id_passo='+id_passo+'&codice_commessa='+codice_commessa+'&part_number='+part_number, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    },
    async sendDataAltro(token, formData, id_pezzo) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/upload_images_altro?token="+token+'&id_pezzo='+id_pezzo, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    },
    async closeOne(token, data) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/close_one?token="+token, data).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, data) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/delete_one?token="+token, data).then(response => {
            return response.data;
        });
    },
    async getOne(token, id_pezzo) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/get_one?token="+token+'&_id='+id_pezzo).then(response => {
            return response.data;
        });
    },
    async getMultiple(token, data) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/get_multiple?token="+token, data).then(response => {
            return response.data;
        });
    },
    async salvaNotePezzo(token, data) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/pezzi/salva_note_pezzo?token="+token, data).then(response => {
            return response.data;
        });
    },
}